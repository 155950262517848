<template>
  <div class="wraps-iim">
    <div class="topeach-policy-list">
      <span class="info-tips"> {{ xq.name }}</span>
    </div>
    <div class="topeach-policy-list">
      <span class="left-info-tips">
        <i class="el-icon-time"></i
        ><span>{{ xq.created_at | formatDate }}</span>
      </span>
    </div>
    <ul class="policy-all-details">
      <li class="each-check-list">
        <span class="rg-publish-time">项目持有人：</span
        ><span class="rg-de-box">{{ xq.holder ? xq.holder : "无" }}</span>
      </li>
      <li class="each-check-list">
        <span class="rg-publish-time">工作单位：</span
        ><span class="rg-de-box">{{ xq.work ? xq.work : "无" }}</span>
      </li>

      <li class="each-check-list">
        <span class="rg-publish-time">联系电话：</span
        ><span class="rg-de-box">{{ xq.phone ? xq.phone : "无" }}</span>
      </li>

      <li class="each-check-list">
        <span class="rg-publish-time">行业分类：</span
        ><span class="rg-de-box">
          {{ xq.industry ? xq.industry.name : "无" }}
        </span>
      </li>
      <li class="each-check-list">
        <span class="rg-publish-time">产业分类：</span
        ><span class="rg-de-box"> {{ xq.xtype ? xq.xtype.name : "无" }} </span>
      </li>
      <li class="each-check-list">
        <span class="rg-publish-time">项目来源：</span
        ><span class="rg-de-box"> {{ xq.projecty ? xq.projecty : "无" }} </span>
      </li>
      <li class="each-check-list">
        <span class="rg-publish-time">项目所处阶段：</span
        ><span class="rg-de-box"> {{ xq.projects ? xq.projects : "无" }} </span>
      </li>
      <li class="each-check-list">
        <span class="rg-publish-time">技术领域：</span
        ><span class="rg-de-box">
          {{ xq.territory ? xq.territory.name : "无" }}
        </span>
      </li>
    </ul>

    <div class="wrap-all-tipbox">
      <div class="left-info-title">项目简介：</div>

      <div class="wrap-tiptit-box">
        <p>
          {{ xq.project_say ? xq.project_say : "无" }}
        </p>
      </div>
      <div class="left-info-title">技术/产品创新性：</div>

      <div class="wrap-tiptit-box">
        <p>
          {{ xq.technology ? xq.technology : "无" }}
        </p>
      </div>
      <div class="left-info-title">合作意向：</div>

      <div class="wrap-tiptit-box">
        <p>
          {{ xq.cooperation ? xq.cooperation : "无" }}
        </p>
      </div>
      <div class="left-info-title">寻求合作者：</div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="联系人" width="300">
        </el-table-column>
        <el-table-column prop="phone" label="联系电话"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { AchievementsXq, HR } from "@/common/js/api";
import { formatDate } from "@/common/js/times"; //时间
export default {
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      id: "",
      xq: {},
      tableData: [],
    };
  },
  watch: {
    item: {
      handler(val, oldVal) {
        if (this.item.dialogVisible == true && this.id != val.id) {
          this.id = val.id;
          this.achievementsXq(val.id);
        }
      },
      deep: true,
    },
  },
  created() {
    this.id = this.item.id;
    this.achievementsXq(this.id);
  },
  methods: {
    //  我发布的成果详情
    achievementsXq(id) {
      AchievementsXq({
        id: id,
      })
        .then((res) => {
          this.xq = res.data;
           this.hr(id);
        })
        .catch(() => {});
    },
    // 寻求合作名单
    hr(id) {
      HR({ id: id })
        .then((res) => {
          console.log(111111111, res);
          this.tableData = res.data;
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.topeach-policy-list {
  font-size: 12px;
  margin-top: 5px;
  .info-tips {
    color: #000;
    font-weight: 700;
    font-size: 16px;
  }
  .left-info-tips {
    color: #b1afaf;
    margin: 5px 0 20px;
  }
}
.policy-all-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.each-check-list {
  flex: 50%;
  margin-top: 12px;
  .rg-publish-time {
    color: #3a93eb;
    width: 80px;
  }
  .rg-de-box {
    width: 85%;
    line-height: 1.5;
  }
}
.left-info-title {
  margin: 25px 0 10px;
  color: #3a93eb;
}

.wrap-tiptit-box {
  line-height: 1.5;
  color: #010101;
}
</style>